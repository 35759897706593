import { useContext } from 'react';
import { FeedContext } from '@/models/FeedContextProvider';
import FeedFooter from './FeedFooter';
import { useParams } from 'react-router-dom';
import FeedVlist from '@/components/FeedVlist';
import NoFeedItems from '@/components/NoFeedItems';

export default function Feed() {
  const { feedId } = useParams();
  const {
    feedMap,
  } = useContext(FeedContext);

  return (
    <>
      {
        feedMap?.length > 0
          ? (
            <FeedVlist />
          )
          : (
            <NoFeedItems
              size={feedMap?.length}
              feedId={feedId}
            />
          )
      }
      <FeedFooter />
    </>
  )
}
