import { selectTranscription } from "@/utils";
import { PreferredLanguage } from "@/models/languages";
import { Transcription } from "@/db/types";

type Props = {
  preferredLanguage: PreferredLanguage;
  transcriptions: Transcription[];
};
export default function RichTranscript({
  preferredLanguage,
  transcriptions,
}: Props) {
  const { simpleHtml, richTranscript, textTranscript } = selectTranscription(
    preferredLanguage,
    transcriptions,
  );
  const activeTranscript = simpleHtml ?? richTranscript ?? textTranscript;
  return (
    <div
      style={{
        whiteSpace: "pre-line",
        wordBreak: "break-word",
      }}
      dangerouslySetInnerHTML={{
        __html: activeTranscript?.transcriptionContent,
      }}
    />
  );
}
