import Locator from '@/locator';
import { Box, Typography, useTheme } from '@mui/material';

export default function NoFeedItems({feedId, size}:{feedId: string; size: number}){

  const theme = useTheme();
    return (
      <Box
        aria-label={Locator.feed.items.list}
        data-testid={Locator.feed.testId(feedId)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height:
            size === 0 ? "calc(100svh - 165px)" : "auto",
          color: theme.palette.secondary.light,
        }}
      >
        <Typography
          variant="h6"
          component="h4"
          color={theme.palette.primary.main}
          fontWeight={700}
        >
          There are no messages yet
        </Typography>
      </Box>
    )
  }
