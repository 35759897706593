import { upsertAccountEvent } from "@/data/oldWorld";
import { createManyWorkspaceMembership } from "@/data/pg/bulkInserts";
import {
  deleteWsDraft,
  upsertDirectWsInvitation,
  upsertWorkspace,
  upsertWorkspaceMembership,
  upsertWsBroadcastAction,
  upsertWsBroadcastRecipient,
  upsertWsDraft,
  upsertWsFeed,
  upsertWsItem,
  upsertWsLink,
  upsertWsPermission,
  upsertWsScheduleTrigger,
  upsertWsTranscription,
} from "@/data/pg/updates";
import { db } from "@/db/db";
import { accountEvent as accountEventTable, feed, item } from "@/db/schema";
import { UxContext } from "@/models/UxStateProvider";
import { handleErrorResponse } from "@/utils";
import cuid from "cuid";
import { and, eq } from "drizzle-orm";
import React, { createContext, useContext } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import {
  AccountEventRequest,
  AccountEventType,
  CreateWorkspaceDirectInvitationResponse,
  CreateWorkspaceScheduledBroadcastResponse,
  PublishBroadcastResponse,
  WorkspaceInvitation,
  WorkspaceRole,
  WsFeed,
  WsItem,
  WsWorkflowItem,
} from "web-client/api/data-contracts";
import Client from "web-client/client";
import { DataContext } from "./DataProvider";
import { CurrentFeedContext } from "./StateProviders/currentFeedProvider";
import { MyAccountContext } from "./StateProviders/myAccountProvider";
import { TelemetryContext } from "./TelemetryProvider";
import { Workspace } from "@/db/types";

type ActionState = {
  removeFeed?: (workspaceId: string, feedId: string) => Promise<void>;
  accountEvent?: (event: AccountEventType, data: any) => void;
  createScheduledWorkflowItem?: ({
    workspaceId,
    workflowItemId,
    feedIds,
    scheduledDate,
    scheduledCron,
    timezone,
  }: {
    workspaceId: string;
    workflowItemId: string;
    feedIds: Array<string>;
    scheduledDate?: string;
    scheduledCron?: string;
    timezone?: string;
  }) => Promise<CreateWorkspaceScheduledBroadcastResponse>;
  updateScheduledWorkflowItem?: ({
    workspaceId,
    scheduleId,
    feedIds,
    scheduledDate,
    scheduledCron,
    timezone,
  }: {
    workspaceId: string;
    scheduleId: string;
    feedIds: Array<string>;
    scheduledDate?: string;
    scheduledCron?: string;
    timezone?: string;
  }) => Promise<void>;
  deleteItem?: (itemId: string) => Promise<void>;
  createWorkflowItem?: (
    workspaceId: string,
    contentId?: string,
    displayName?: string,
    text?: string,
  ) => Promise<WsWorkflowItem>;
  deleteWorkflowItem?: (
    workspaceId: string,
    workflowItemId: string,
  ) => Promise<void>;
  updateWorkspace?: (workspaceId: string, name: string) => Promise<void>;
  publishBroadcast?: ({
    workspaceId,
    contentId,
    workspaceMembershipIds,
    feedIds,
    inputText,
  }: {
    workspaceId: string;
    contentId: string;
    workspaceMembershipIds?: string[];
    feedIds?: string[];
    inputText?: string;
  }) => Promise<PublishBroadcastResponse>;
  publishToWorkspaceFeed?: ({
    workspaceId,
    feedId,
    itemId,
    contentId,
    groupId,
    url,
    text,
    preferredLanguage,
    isSilent,
  }: {
    workspaceId: string;
    feedId: string;
    itemId?: string;
    contentId: string;
    groupId?: string;
    url?: string;
    text?: string;
    preferredLanguage?: string;
    isSilent?: boolean;
  }) => Promise<WsItem>;
  muteUnmute?: (
    workspaceId: string,
    feedId: string,
    workspaceMemberId: string,
    type: string,
  ) => Promise<void>;
  promoteMemberToOwner?: (
    workspaceId: string,
    feedId: string,
    workspaceMemberId: string,
  ) => Promise<void>;
  demoteOwnerToMember?: (
    workspaceId: string,
    feedId: string,
    workspaceMemberId: string,
  ) => Promise<void>;
  removeMemberFromFeed?: (
    workspaceId: string,
    feedId: string,
    workspaceMemberId: string,
  ) => Promise<void>;
  createWorkspaceFeed?: (
    workspaceId: string,
    title: string,
    isPrivate: boolean,
    readOnly: boolean,
    isSilent?: boolean,
    autoSubscribe?: boolean,
  ) => Promise<WsFeed>;
  updateWorkspaceFeed?: (
    workspaceId: string,
    feedId: string,
    title: string,
    isSilent?: boolean,
  ) => void;
  createWorkspaceDm?: (
    workspaceId: string,
    workspaceMembershipIds: string[],
  ) => Promise<WsFeed>;
  addWorkspaceMembersToFeed?: (
    workspaceId: string,
    feedId: string,
    workspaceMembershipIds: string[],
  ) => void;
  sendWorkspaceInvites?: (
    workspaceId: string,
    emails?: string[],
    phoneNumbers?: string[],
    sendEmail?: boolean,
    invites?: WorkspaceInvitation[],
  ) => Promise<CreateWorkspaceDirectInvitationResponse>;
  updateMemberRole?: (
    workspaceId: string,
    workspaceMembershipIds: string[],
    role: WorkspaceRole,
  ) => Promise<void>;
  removeMember?: (
    workspaceId: string,
    workspaceMembershipIds: string[],
  ) => Promise<void>;
  sendFeedback?: (message: string) => Promise<void>;
};

export const ActionContext = createContext<ActionState>({});

type Props = {
  children: React.ReactNode | React.ReactNode[];
  client: Client;
};

const ActionsProvider = ({ children, client }: Props) => {
  const { showBoundary } = useErrorBoundary();
  const navigate = useNavigate();
  const { userReadOnlyMode } = useContext(UxContext);
  const { trackAction, finishAction } = useContext(TelemetryContext);

  const {
    fetchWorkspaceMembership,
    listFeedPermissions,
    appContext,
    deviceContext,
  } = useContext(DataContext);

  const { currentFeedId } = useContext(CurrentFeedContext);

  const { myAccount } = useContext(MyAccountContext);

  const removeFeed = React.useCallback(
    async (workspaceId: string, feedId: string) => {
      console.log("Removing feed", workspaceId, feedId);
      await client
        .unSubscribeFromWorkspaceFeed(workspaceId, feedId)
        .catch(async (e) => {
          const error = await handleErrorResponse(e);
          showBoundary(error);
        });
      if (currentFeedId === feedId) {
        navigate(`/workspaces/${workspaceId}`);
      }
    },
    [client, currentFeedId, navigate, showBoundary],
  );

  const accountEvent = React.useCallback(
    async (name: AccountEventType, data: any) => {
      if (userReadOnlyMode === true) {
        console.log("Read only mode enabled, not sending event");
        return;
      }

      if (!myAccount) return;
      if (data.feedId && data.feedItemId) {
        const existingEvent = await db.query.accountEvent
          .findFirst({
            where: and(
              eq(accountEventTable.name, name),
              eq(accountEventTable.feedId, data.feedId),
              eq(accountEventTable.itemId, data.feedItemId),
              eq(accountEventTable.accountId, myAccount.id),
            ),
          })
          .execute();

        if (existingEvent) {
          return;
        }
      }
      const event: AccountEventRequest = {
        id: cuid(),
        name,
        ...data,
        timestamp: new Date(Date.now()).toISOString(),
        accountId: myAccount.id,
      };
      await upsertAccountEvent(event);
      client.createAccountEvent(event);
    },
    [client, myAccount?.id, userReadOnlyMode],
  );

  const deleteItem = React.useCallback(
    async (itemId: string) => {
      const feedItem = await db.query.item
        .findFirst({ where: eq(item.id, itemId) })
        .execute();
      const feedRecord = await db.query.feed
        .findFirst({
          where: eq(feed.id, feedItem?.feedId),
        })
        .execute();
      if (!feedRecord || !feedRecord.workspaceId || !itemId) return;
      const res = await client.deleteWorkspaceFeedItem(
        feedRecord.workspaceId,
        feedRecord.id,
        itemId,
      );

      if (res?.item) {
        upsertWsItem(res.item);
      }
    },
    [client, db],
  );

  const createWorkspace = React.useCallback(
    async (name: string) => {
      const { workspace, workspaceMemberships } =
        await client.createWorkspace(name);
      await upsertWorkspace(workspace);
      await createManyWorkspaceMembership(workspaceMemberships);
      return workspace;
    },
    [client, db],
  );

  const updateWorkspace = React.useCallback(
    async (workspaceId: string, name: string) => {
      const { workspace } = await client.updateWorkspace(workspaceId, { name });
      upsertWorkspace(workspace);
    },
    [client],
  );

  const createWorkspaceFeed = React.useCallback(
    async (
      workspaceId: string,
      title: string,
      isPrivate: boolean,
      readOnly: boolean,
      isSilent?: boolean,
      autoSubscribe?: boolean,
    ) => {
      const { feed, workspaceMemberships, permissions } =
        await client.createWorkspaceFeed({
          workspaceId,
          title,
          isPrivate,
          readOnly,
          isSilent,
          autoSubscribe,
        });

      await upsertWsFeed(feed);
      for (const membership of workspaceMemberships) {
        upsertWorkspaceMembership(membership);
      }
      for (const p of permissions) {
        upsertWsPermission(p);
      }

      return feed;
    },
    [client],
  );

  const updateWorkspaceFeed = React.useCallback(
    async (
      workspaceId: string,
      feedId: string,
      newTitle: string,
      isSilent = false,
    ) => {
      const { feed } = await client.updateWorkspaceFeed({
        workspaceId,
        feedId,
        title: newTitle,
        isSilent,
      });
      upsertWsFeed(feed);
    },
    [client],
  );

  const createWorkspaceDm = React.useCallback(
    async (workspaceId: string, workspaceMembershipIds: string[]) => {
      const { feed, workspaceMemberships, permissions } =
        await client.createWorkspaceDm({
          workspaceId,
          workspaceMembershipIds,
        });

      await upsertWsFeed(feed);
      for (const membership of workspaceMemberships) {
        upsertWorkspaceMembership(membership);
      }
      for (const p of permissions) {
        upsertWsPermission(p);
      }

      return feed;
    },
    [client, db],
  );

  const addWorkspaceMembersToFeed = React.useCallback(
    async (
      workspaceId: string,
      feedId: string,
      workspaceMembershipIds: string[],
    ) => {
      const res = await client.addWorkspaceMembersToFeed(
        workspaceId,
        feedId,
        workspaceMembershipIds,
      );

      for (const p of res?.permissions || []) {
        upsertWsPermission(p);
      }
    },
    [client, db],
  );

  const sendWorkspaceInvites = React.useCallback(
    async (
      workspaceId: string,
      emails: string[],
      phoneNumbers: string[],
      sendEmail: boolean,
      invites: WorkspaceInvitation[],
    ) => {
      const res = await client.createWorkspaceInvitations(
        workspaceId,
        emails,
        phoneNumbers,
        sendEmail,
        invites,
      );
      const invitations = res?.invitations || [];
      const workspaceMemberships = res?.workspaceMemberships || [];
      for (const i of invitations) {
        upsertDirectWsInvitation(i);
      }
      for (const m of workspaceMemberships) {
        upsertWorkspaceMembership(m);
      }

      return {
        invitations,
        workspaceMemberships,
      };
    },
    [client, db],
  );

  const updateMemberRole = React.useCallback(
    async (
      workspaceId: string,
      workspaceMembershipIds: string[],
      role: WorkspaceRole,
    ) => {
      const resp = await client.updateWorkspaceMember(
        workspaceId,
        workspaceMembershipIds,
        role,
      );

      for (const workspaceMembership of resp?.workspaceMemberships || []) {
        console.log("Upserting workspace membership", workspaceMembership);
        upsertWorkspaceMembership(workspaceMembership);
      }
    },
    [client, db],
  );

  const removeMember = React.useCallback(
    async (workspaceId: string, workspaceMembershipIds: string[]) => {
      console.log(
        "Removing workspaceMembershipRecord",
        workspaceMembershipIds,
        workspaceId,
      );
      const resp = await client.removeWorkspaceMember(
        workspaceId,
        workspaceMembershipIds,
      );
      console.log("Removing workspaceMembershipRecord", resp);

      for (const workspaceMembership of resp?.workspaceMemberships || []) {
        upsertWorkspaceMembership(workspaceMembership);
      }
    },
    [client, db],
  );

  const muteUnmute = React.useCallback(
    async (
      workspaceId: string,
      feedId: string,
      workspaceMemberId: string,
      type: string,
    ): Promise<any> => {
      const workspaceMembership = await fetchWorkspaceMembership(
        workspaceMemberId,
        workspaceId,
      );

      const permissions = await listFeedPermissions(
        workspaceMembership.id,
        feedId,
      );

      const permission = permissions.find((perm) => perm.name === "write");
      await upsertWsPermission({
        ...permission,
        name: "write",
        id: permission.id,
        enabled: type !== "mute",
      });
      client.muteUnmuteWorkspaceFeedMember(
        workspaceId,
        feedId,
        workspaceMemberId,
        type,
      );
    },
    [client, fetchWorkspaceMembership, listFeedPermissions],
  );

  const promoteMemberToOwner = React.useCallback(
    async (workspaceId: string, feedId: string, workspaceMemberId: string) => {
      const resp = await client.promoteDemoteMember(
        workspaceId,
        feedId,
        workspaceMemberId,
        "promote",
      );
      for (const p of resp?.permissions || []) {
        upsertWsPermission(p);
      }
    },
    [client, db],
  );

  const demoteOwnerToMember = React.useCallback(
    async (workspaceId: string, feedId: string, workspaceMemberId: string) => {
      const resp = await client.promoteDemoteMember(
        workspaceId,
        feedId,
        workspaceMemberId,
        "demote",
      );
      for (const p of resp?.permissions || []) {
        upsertWsPermission(p);
      }
    },
    [client, fetchWorkspaceMembership, listFeedPermissions],
  );

  const removeMemberFromFeed = React.useCallback(
    async (workspaceId: string, feedId: string, workspaceMemberId: string) => {
      const workspaceMembership = await fetchWorkspaceMembership(
        workspaceMemberId,
        workspaceId,
      );

      const permissions = await listFeedPermissions(
        workspaceMembership.id,
        feedId,
      );

      client.removeMemberFromFeed(workspaceId, feedId, workspaceMemberId);
      for (const permission of permissions) {
        upsertWsPermission({
          ...permission,
          name: permission.name,
          id: permission.id,
          enabled: false,
        });
      }
    },
    [client, fetchWorkspaceMembership, listFeedPermissions],
  );

  const createWorkflowItem = React.useCallback(
    async (
      workspaceId: string,
      contentId?: string,
      displayName?: string,
      text?: string,
    ) => {
      let workflowItem;
      if (text) {
        workflowItem = await client.createWorkspaceWorkflowItem({
          workspaceId: workspaceId,
          displayName: displayName,
          inputText: text,
        });
        if (!workflowItem) throw new Error("WorkflowItem not created");
      } else {
        workflowItem = await client.createWorkspaceWorkflowItem({
          workspaceId: workspaceId,
          contentId: contentId,
          displayName: displayName,
        });
        if (!workflowItem) throw new Error("WorkflowItem not created");
      }

      if (workflowItem) {
        upsertWsDraft(workflowItem);
      }

      return workflowItem;
    },
    [client, db],
  );

  const deleteWorkflowItem = React.useCallback(
    async (workspaceId: string, workflowItemId: string) => {
      await client.deleteWorkspaceWorkflowItem(workspaceId, workflowItemId);

      await deleteWsDraft(workflowItemId);
    },
    [client, db],
  );

  const createScheduledWorkflowItem = React.useCallback(
    async ({
      workspaceId,
      workflowItemId,
      feedIds,
      scheduledDate,
      scheduledCron,
      timezone,
    }: {
      workspaceId: string;
      workflowItemId: string;
      feedIds: Array<string>;
      scheduledDate?: string;
      scheduledCron?: string;
      timezone?: string;
    }) => {
      let props = {
        workspaceId: workspaceId,
        feedIds,
        workflowItemId: workflowItemId,
      };

      if (scheduledDate) {
        props = { ...props, date: scheduledDate };
      }

      if (scheduledCron) {
        props = { ...props, cron: scheduledCron, timezone: timezone };
      }

      const scheduledBroadcast = await client.createScheduledBroadcast(props);

      if (!scheduledBroadcast)
        throw new Error("scheduledBroadcast not created");

      console.log("created broadcast", scheduledBroadcast);
      if (scheduledBroadcast) {
        if (scheduledBroadcast?.scheduleTrigger) {
          upsertWsScheduleTrigger(scheduledBroadcast?.scheduleTrigger);
        }

        if (scheduledBroadcast?.broadcastAction) {
          upsertWsBroadcastAction(scheduledBroadcast?.broadcastAction);
        }

        if (scheduledBroadcast?.broadcastRecipients) {
          for (const broadcastRecipient of scheduledBroadcast?.broadcastRecipients ||
            []) {
            upsertWsBroadcastRecipient(broadcastRecipient);
          }
        }
      }

      return scheduledBroadcast;
    },
    [client, db],
  );

  const updateScheduledWorkflowItem = React.useCallback(
    async ({
      workspaceId,
      scheduleId,
      feedIds,
      scheduledDate,
      scheduledCron,
      timezone,
    }: {
      workspaceId: string;
      scheduleId: string;
      feedIds: Array<string>;
      scheduledDate?: string;
      scheduledCron?: string;
      timezone?: string;
    }) => {
      type PropsType = {
        workspaceId: string;
        feedIds: string[];
        scheduleId: string;
        date?: string;
        cron?: string;
        timezone?: string;
      };

      let props: PropsType = {
        workspaceId: workspaceId,
        feedIds,
        scheduleId: scheduleId,
      };

      if (scheduledDate) {
        props = { ...props, date: scheduledDate };
      }

      if (scheduledCron) {
        props = { ...props, cron: scheduledCron, timezone: timezone };
      }

      const scheduledBroadcast = await client.updateScheduledBroadcast(props);

      if (!scheduledBroadcast)
        throw new Error("scheduledBroadcast not updated");

      console.log("updated broadcast", scheduledBroadcast);
      if (scheduledBroadcast) {
        if (scheduledBroadcast?.scheduleTrigger) {
          upsertWsScheduleTrigger(scheduledBroadcast?.scheduleTrigger);
        }

        if (scheduledBroadcast?.broadcastAction) {
          upsertWsBroadcastAction(scheduledBroadcast?.broadcastAction);
        }

        if (scheduledBroadcast?.broadcastRecipients) {
          for (const broadcastRecipient of scheduledBroadcast?.broadcastRecipients ||
            []) {
            upsertWsBroadcastRecipient(broadcastRecipient);
          }
        }
      }
    },
    [client, db],
  );

  const publishToWorkspaceFeed = React.useCallback(
    async ({
      workspaceId,
      feedId,
      itemId,
      contentId,
      groupId,
      text,
      preferredLanguage,
      isSilent,
    }: {
      workspaceId: string;
      feedId: string;
      itemId?: string;
      contentId: string;
      groupId?: string;
      text?: string;
      preferredLanguage?: string;
      isSilent?: boolean;
    }) => {
      const { item, links, transcriptions } = await client.publishTTS({
        workspaceId,
        feedId,
        itemId,
        contentId,
        groupId,
        text,
        preferredLanguage,
        isSilent,
      });
      upsertWsItem(item);
      for (const link of links || []) {
        upsertWsLink(link);
      }
      for (const transcription of transcriptions || []) {
        upsertWsTranscription(transcription);
      }
      return item;
    },
    [client, db],
  );

  const publishBroadcast = React.useCallback(
    async ({
      workspaceId,
      contentId,
      workspaceMembershipIds,
      feedIds,
      inputText,
    }: {
      workspaceId: string;
      contentId: string;
      workspaceMembershipIds?: string[];
      feedIds?: string[];
      inputText?: string;
    }) => {
      trackAction(
        { action: "publish_broadcast", target: contentId },
        {
          workspaceId,
          workspaceMembershipIds,
          feedIds,
          inputText,
        },
      );
      const broadcast = await client.publishBroadcast({
        workspaceId,
        contentId,
        workspaceMembershipIds,
        feedIds,
        inputText,
      });
      finishAction({ action: "publish_broadcast", target: contentId });

      return broadcast;
    },
    [client, db],
  );

  const sendFeedback = React.useCallback(
    async (message: string) => {
      const feedback = await client.uploadLogs({
        deviceContext,
        appContext,
        logs: "",
        userMessage: message,
      });

      return feedback;
    },
    [client, appContext, deviceContext],
  );

  const actionState: ActionState = {
    accountEvent,
    addWorkspaceMembersToFeed,
    createScheduledWorkflowItem,
    createWorkflowItem,
    createWorkspaceFeed,
    createWorkspaceDm,
    deleteItem,
    deleteWorkflowItem,
    demoteOwnerToMember,
    muteUnmute,
    promoteMemberToOwner,
    publishToWorkspaceFeed,
    publishBroadcast,
    removeFeed,
    removeMember,
    removeMemberFromFeed,
    sendWorkspaceInvites,
    sendFeedback,
    updateMemberRole,
    updateWorkspace,
    updateWorkspaceFeed,
    updateScheduledWorkflowItem,
  };

  return (
    <ActionContext.Provider value={actionState}>
      {children}
    </ActionContext.Provider>
  );
};
export default ActionsProvider;
