import { useContext, useEffect, useRef, useState } from "react";
import type React from "react";
import {
  Box,
  Button,
  Collapse,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import TranscriptDetails from "@/components/TranscriptDetails";
import { selectTranscription } from "@/utils";
import * as Icons from "@mui/icons-material";
import { PreferredLanguage } from "@/models/languages";
import { File, Link as LinkType, Transcription } from "@/db/types";
import BasicButtonStyles from "@/theme/BasicButtonStyles";
import { FeedContext } from "@/models/FeedContextProvider";
import { ActionContext } from "@/models/ActionsProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import RichTranscript from "@/components/RichTranscript";
import HideShowTranscriptButton from '@/components/HideShowTranscriptButton';

type TranscriptProps = {
  displayArtifact: boolean;
  feedItemId: string;
  files?: File[];
  links?: LinkType[];
  mine: boolean;
  preferredLanguage: PreferredLanguage;
  showTranscription: boolean;
  transcriptionContent: Transcription;
  transcriptions: Transcription[];
  unreliableTranscription: boolean;
  unreliableTranscriptLabel: string;
  feedId: string;
  itemId: string;
};
export default function DisplayTranscript({
  displayArtifact,
  feedItemId,
  files,
  links,
  mine,
  preferredLanguage,
  transcriptionContent,
  transcriptions,
  unreliableTranscription,
  unreliableTranscriptLabel,
  feedId,
  itemId,
}: TranscriptProps) {
  const theme = useTheme();
  const { accountEvent } = useContext(ActionContext);
  const { itemTranscriptState, setItemTranscriptState } =
    useContext(FeedContext);
  const { ampli } = useContext(TrackingContext);

  const showTranscription = itemTranscriptState?.includes(itemId);
  const maxHeightBox = 144;
  const { normalButtonStyles, normalIconStyles } = BasicButtonStyles();
  const [toggleShowMoreButton, setToggleShowMoreButton] =
    useState<boolean>(false);
  const [renderText, setRenderText] = useState<boolean>(false);
  const [boxSize, setBoxSize] = useState<number>(0);
  const shadowBox = useRef<HTMLDivElement>();
  const wrapperBox = useRef<HTMLDivElement>();

  const { textTranscript } = selectTranscription(
    preferredLanguage,
    transcriptions,
  );

  const toggleShowTranscription = (show: boolean) => {
    if (show) {
      accountEvent("Expanded Transcript", {
        feedId,
        feedItemId: itemId,
      });
      ampli.openTranscript({ itemId: itemId, feedId: feedId });
      setItemTranscriptState([...itemTranscriptState, itemId]);
    } else {
      setItemTranscriptState(itemTranscriptState.filter((id) => id !== itemId));
    }
  };

  const calculateHeight = (
    offsetHeight: number,
    maxHeight: number,
    unreliableTranscription: boolean,
  ) => {
    const overMaxHeight = offsetHeight > maxHeight;
    const height = !overMaxHeight ? offsetHeight : maxHeight;
    return !unreliableTranscription ? height : 48 + height;
  };

  useEffect(() => {
    if (shadowBox.current) {
      const box = shadowBox.current;
      const tooLarge = box?.offsetHeight > maxHeightBox;
      const boxHeight = calculateHeight(
        box.offsetHeight,
        maxHeightBox,
        unreliableTranscription,
      );
      setBoxSize(() => boxHeight);
      if (unreliableTranscription || displayArtifact) {
        setToggleShowMoreButton(() => true);
      } else {
        setToggleShowMoreButton(() => tooLarge);
      }
      setRenderText(() => true);
    }
  }, [shadowBox?.current]);

  // modify the transcript content to ensure duplicate text does not exist
  const __html = `shadow - ${transcriptionContent?.transcriptionContent?.slice(2)}`;

  return (
    <Box
      id={`${feedItemId}-content`}
      ref={wrapperBox}
      role="textbox"
      data-test="transcription-content"
      aria-label={textTranscript?.transcriptionContent}
    >
      <Collapse
        orientation="vertical"
        in={showTranscription}
        collapsedSize={boxSize}
        sx={{
          margin: 0,
        }}
      >
        {renderText && (
          <>
            {unreliableTranscription && (
              <Stack
                sx={{
                  background: mine
                    ? theme.palette.brand.primary.darker
                    : theme.palette.secondary.dark,
                  px: 1.5,
                  py: 1,
                  borderRadius: "8px",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: 0.5,
                  marginBottom: "8px",
                }}
              >
                <Icons.InfoOutlined
                  role="presentation"
                  sx={{
                    color: theme.palette.warning.dark,
                    fontSize: "1rem",
                    marginTop: "3px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    fontStyle: "italic",
                    lineHeight: "24px",
                  }}
                >
                  {unreliableTranscriptLabel}
                </Typography>
              </Stack>
            )}
            <RichTranscript
              preferredLanguage={preferredLanguage}
              transcriptions={transcriptions}
            />
          </>
        )}
        {!displayArtifact && showTranscription && (
          <TranscriptDetails
            transcriptionContent={transcriptionContent}
            files={files}
            links={links}
          />
        )}
      </Collapse>
      {toggleShowMoreButton && (
        <HideShowTranscriptButton
          mine={mine}
          displayArtifact={displayArtifact}
          showTranscription={showTranscription}
          onClick={() => toggleShowTranscription(!showTranscription)}
        />
      )}
      <Box
        sx={{
          whiteSpace: "pre-line",
          wordBreak: "break-word",
          position: "fixed",
          opacity: 0,
          width: `${wrapperBox?.current?.offsetWidth ?? 400}px`,
          right: 999999,
          bottom: 99999,
          zIndex: "-1000",
        }}
        ref={shadowBox}
        dangerouslySetInnerHTML={{ __html }}
      />
    </Box>
  );
}
