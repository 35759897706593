import { Box, Button, Typography, useTheme } from "@mui/material";
import ModalForm from "@/elements/ModalForm";
import React from "react";

export default function InvalidWorkspaceModal({
  open,
  allowClose,
  workspaceId,
}: { open: boolean; allowClose: boolean; workspaceId: string }) {
  const theme = useTheme();
  const redirectToWorkspaceRoot = () => {
    window.location.href = "/workspaces";
  };
  return (
    <ModalForm open={open} allowClose={allowClose}>
      <Box sx={{ textAlign: "center" }}>
        <Box sx={{ mb: 1 }}>
          <Typography
            variant="h5"
            component="h2"
            sx={{ mb: 2, fontWeight: 700 }}
          >
            Invalid Workspace
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography color={theme.palette.primary.main} fontWeight={700}>
            The workspace{" "}
            <strong
              style={{ background: theme.palette.info.main, padding: "2px" }}
            >
              {workspaceId}
            </strong>{" "}
            is invalid.
          </Typography>
        </Box>
        <Box>
          <Button
            variant={"contained"}
            onClick={() => redirectToWorkspaceRoot()}
          >
            Go Home
          </Button>
        </Box>
      </Box>
    </ModalForm>
  );
}
