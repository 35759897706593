import {
  createManyWsAudioEncoding,
  createManyWsDisplayArtifact,
  createManyWsFile,
  createManyWsItem,
  createManyWsLink,
  createManyWsPAM,
  createManyWsTranscription,
} from "@/data/pg/bulkInserts";
import { db } from "@/db/db";
import {
  accountEvent,
  feed,
  item,
  permission,
  workspaceCommandAlias,
  workspaceMembership,
} from "@/db/schema";
import {
  and,
  desc,
  eq,
  getTableColumns,
  gt,
  gte,
  inArray,
  lte,
  ne,
  or,
  sql,
} from "drizzle-orm";
import { GetWorkspaceFeedItemsResponse } from "web-client/api/data-contracts";
import Client from "web-client/client";
import { unreadClearEvents } from "../UnreadsContextProvider";
import { downloadOldestFeedItemEvents } from "./bootstrap";

const BOOTSTRAP_ITEMS_PER_PAGE = 10000;

export async function initialFeedLoad(
  client: Client,
  feedId: string,
): Promise<boolean> {
  const feedRecord = await db.query.feed
    .findFirst({
      where: eq(feed.id, feedId),
    })
    .execute();
  if (!feedRecord) {
    return Promise.reject(`Feed not found: ${feedId}`);
  }
  const workspaceId = feedRecord?.workspaceId;
  if (feedRecord.loadedFirstPage && feedRecord.loadedEvents) {
    console.log("ALREADY LOADED", feedId);
    return;
  }

  if (!feedRecord.loadedFirstPage) {
    const items = (
      await db.query.item
        .findMany({
          where: eq(item.feedId, feedId),
          orderBy: desc(item.createdAt),
          limit: 10,
        })
        .execute()
    ).filter((i) => !i.loadedContent);
    // if the refresh search param exist, wait for the download
    const refresh = new URLSearchParams(window.location.search).get("refresh");
    if (refresh === "true") {
      await downloadFeedItemContent(
        client,
        workspaceId,
        items.map((i) => i.id),
      );
    } else {
      downloadFeedItemContent(
        client,
        workspaceId,
        items.map((i) => i.id),
      );
    }
  }

  await db
    .update(feed)
    .set({
      loadedFirstPage: true,
      loadedEvents: true,
      loadedPermissions: true,
    })
    .where(eq(feed.id, feedId))
    .execute();
  return true;
}

export async function downloadPaginatedBootstrapFeedItems(
  client: Client,
  workspaceId: string,
  pagesToFetch = 100,
  startingPage = 0,
) {
  let page = startingPage;
  const parallelRequests = 1;
  let count = 0;

  while (page < startingPage + pagesToFetch) {
    const promises: Promise<GetWorkspaceFeedItemsResponse>[] = [];
    for (let index = 0; index < parallelRequests; index++) {
      const currentPage = page;
      promises.push(
        client
          .bootstrapWorkspaceFeedItems(
            workspaceId,
            currentPage,
            BOOTSTRAP_ITEMS_PER_PAGE,
          )
          .then(async (r) => {
            if (!r) return;
            createManyWsItem(r.items);
            return r;
          }),
      );
      page += 1;
    }
    const responses = await Promise.all(promises);
    const currentCount = responses.reduce(
      (acc, r) => acc + r?.items?.length,
      0,
    );
    count += currentCount;

    if (currentCount % BOOTSTRAP_ITEMS_PER_PAGE !== 0 || currentCount === 0) {
      return count;
    }

    if (page > 1000) {
      throw new Error("Too many pages");
    }
  }
  return count;
}

export async function downloadFeedItemContent(
  client: Client,
  workspaceId: string,
  feedItemIds: string[],
) {
  if (feedItemIds.length === 0) return;
  const chunkSize = 40;
  const itemIdChunks = [];
  for (let i = 0; i < feedItemIds.length; i += chunkSize) {
    itemIdChunks.push(feedItemIds.slice(i, i + chunkSize));
  }

  const topPromises = [];

  for (const itemChunk of itemIdChunks) {
    topPromises.push(
      downloadOldestFeedItemEvents(client, itemChunk, workspaceId),
    );
    topPromises.push(
      client.getFeedItemContent(workspaceId, itemChunk).then(async (r) => {
        const promises = [];

        promises.push(createManyWsTranscription(r?.transcriptions || []));
        promises.push(createManyWsAudioEncoding(r?.audioEncodings || []));
        promises.push(createManyWsFile(r?.files || []));
        promises.push(createManyWsLink(r?.links || []));
        promises.push(createManyWsDisplayArtifact(r?.displayArtifacts || []));
        promises.push(createManyWsPAM(r?.pam || []));

        return Promise.all(promises);
      }),
    );
  }
  await Promise.all(topPromises);
  await db
    .update(item)
    .set({
      loadedContent: true,
    })
    .where(inArray(item.id, feedItemIds))
    .execute();
}

export async function processUnreadItemsForFeed({
  feedId,
  myAccountId,
  workspaceId,
}: {
  feedId: string;
  myAccountId: string;
  workspaceId: string;
}) {
  const myWorkspaceMembership = await db.query.workspaceMembership.findFirst({
    where: and(
      eq(workspaceMembership.workspaceId, workspaceId),
      eq(workspaceMembership.accountId, myAccountId),
    ),
  });

  if (!myWorkspaceMembership) {
    throw new Error("My workspace membership not found");
  }

  return initialUnreadItems({
    feedId,
    myAccountId,
    myCurrentWorkspaceRole: myWorkspaceMembership.role,
    myWorkspaceMembershipId: myWorkspaceMembership.id,
    workspaceId,
  });
}

export async function initialUnreadItems({
  feedId,
  myAccountId,
  myCurrentWorkspaceRole,
  myWorkspaceMembershipId,
  workspaceId,
}: {
  feedId: string;
  myAccountId: string;
  myCurrentWorkspaceRole: string;
  myWorkspaceMembershipId: string;
  workspaceId: string;
}) {
  if (
    !feedId ||
    !myAccountId ||
    !myCurrentWorkspaceRole ||
    !workspaceId ||
    !myWorkspaceMembershipId
  ) {
    throw new Error("Missing required parameters");
  }
  const commandMemberships = await db
    .select({ ...getTableColumns(workspaceMembership) })
    .from(workspaceMembership)
    .innerJoin(
      workspaceCommandAlias,
      eq(workspaceMembership.id, workspaceCommandAlias.workspaceMembershipId),
    )
    .where(eq(workspaceCommandAlias.feedId, feedId))
    .execute();

  const isAliasChannel = commandMemberships?.length > 0;

  const aliasAccounts =
    commandMemberships
      ?.filter((ca) => ca && ca.accountId)
      ?.map((alias) => alias?.accountId as string) || [];

  const isOrganizer = myCurrentWorkspaceRole === "member" && isAliasChannel;

  const otherOrganizers = await db
    .select({ accountId: workspaceMembership.accountId })
    .from(workspaceMembership)
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.role, "member"),
        ne(workspaceMembership.accountId, myAccountId),
      ),
    )
    .execute();

  const mappedOrganizers = new Map(
    otherOrganizers?.map((organizer) => [organizer?.accountId, true]),
  );

  if (isOrganizer) {
    mappedOrganizers.set(myAccountId, true);
  }

  const feedReadPermission = await db.query.permission
    .findFirst({
      where: and(
        eq(permission.feedId, feedId),
        eq(permission.workspaceMembershipId, myWorkspaceMembershipId),
        eq(permission.name, "read"),
        eq(permission.enabled, true),
      ),
    })
    .execute();

  const myLatestEvent = await db.query.accountEvent
    .findFirst({
      where: and(
        or(eq(accountEvent.feedId, feedId), eq(accountEvent.itemId, item.id)),
        eq(accountEvent.accountId, myAccountId),
        inArray(accountEvent.name, unreadClearEvents),
      ),
      orderBy: desc(accountEvent.createdAt),
    })
    .execute();

  const myLatestDates = [
    myLatestEvent?.createdAt,
    feedReadPermission?.updatedAt,
    feedReadPermission?.createdAt,
  ]
    .filter((date) => date !== undefined)
    .map((date) => new Date(date))
    .sort((a, b) => b.getTime() - a.getTime());

  const myLatest = myLatestDates[0]?.toISOString();

  const organizerLatestEvent = await db.query.accountEvent
    .findFirst({
      where: and(
        or(eq(accountEvent.feedId, feedId)),
        inArray(accountEvent.accountId, [...mappedOrganizers.keys()]),
        inArray(accountEvent.name, unreadClearEvents),
      ),
      orderBy: desc(accountEvent.createdAt),
    })
    .execute();

  const organizerLatestDates = [
    organizerLatestEvent?.createdAt,
    feedReadPermission?.updatedAt,
    feedReadPermission?.createdAt,
  ]
    .filter((date) => date !== undefined)
    .map((date) => new Date(date))
    .sort((a, b) => b.getTime() - a.getTime());
  const organizerLatest = organizerLatestDates[0]?.toISOString();

  if (isOrganizer && isAliasChannel && organizerLatest) {
    return await Promise.all([
      db
        .update(item)
        .set({
          unread: true,
        })
        .where(
          and(
            eq(item.feedId, feedId),
            inArray(item.accountId, aliasAccounts),
            gt(item.createdAt, organizerLatest),
          ),
        )
        .execute(),
      db
        .update(item)
        .set({
          unread: false,
        })
        .where(
          and(eq(item.feedId, feedId), lte(item.createdAt, organizerLatest)),
        )
        .execute(),
    ]);
  } else if (myLatest) {
    return await Promise.all([
      db
        .update(item)
        .set({
          unread: true,
        })
        .where(
          and(
            eq(item.feedId, feedId),
            ne(item.accountId, myAccountId),
            gt(item.createdAt, myLatest),
          ),
        )
        .execute(),
      db
        .update(item)
        .set({
          unread: false,
        })
        .where(and(eq(item.feedId, feedId), lte(item.createdAt, myLatest)))
        .execute(),
    ]);
  }
}

export async function initializeFromAlias() {
  const rawSQL = sql`
  with items_from_alias as (
    select ${item.id} as id
    from ${item} 
    inner join ${workspaceMembership} on ${item.accountId} = ${workspaceMembership.accountId}
    inner join ${workspaceCommandAlias} on ${item.feedId} = ${workspaceCommandAlias.feedId} and ${workspaceMembership.id} = ${workspaceCommandAlias.workspaceMembershipId}
  )
  update ${item}
  set "isFromAliasMember" = true
  from items_from_alias
  where ${item.id} = items_from_alias.id 
  
  `;

  const results = await db.execute(rawSQL);

  return results;
}
