import Locator from '@/locator';
import { Box } from '@mui/material';
import FeedItem from '@/components/FeedItem';
import { VList } from 'virtua';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '@/models/AppStateProvider';
import { useParams } from 'react-router-dom';
import { FeedContext } from '@/models/FeedContextProvider';
import { CurrentFeedContext } from '@/models/StateProviders/currentFeedProvider';
import { downloadFeedItemContent } from '@/models/actions/initialFeedLoad';

export default function FeedVlist(){
  const { client } = useContext(AppContext);
  const { workspaceId, feedId } = useParams();
  const wls = window?.location?.search; // window location search
  const feedItemId = new URLSearchParams(wls).get("feedItemId") ?? null;
  const [activeFeedItemId, setActiveFeedItemId] = useState<string | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [range, setRange] = useState<number[]>([0, 0]);
  const [scrolling, setScrolling]  = useState<boolean>(false);
  const [initialScroll, setInitialScroll] = useState<boolean>(false)

  useEffect(() => {
    setInitialScroll(()=> false)
  }, [feedId, workspaceId]);

  const {
    feedMap,
    vListRef,
  } = useContext(FeedContext);
  const { currentFeed } = useContext(CurrentFeedContext);

  // build a string representing the ids of the feed items ➞ used as a dependency for useEffect
  const feedMapIds = feedMap
    ?.filter((feedItem) => feedItem?.id !== feedId)
    ?.map((feedItem) => feedItem.id)
    ?.join('-');

  const downloadFeedItems = useCallback(async ()=>{

    const feedMapRange = feedMap.slice(range[0], range[1])

    // only load content for items that are not already loaded
    const idMap = feedMapRange
      .filter((item)=> !item.loadedContent)
      // create an array of ids
      .map((item)=> item.id)
    await downloadFeedItemContent(client, workspaceId, idMap)
  },[range])

  useEffect(() => {
    if(feedMapIds?.length > 0 && !initialScroll){
      const findFirstUnreadIndex = feedMap?.findIndex((feedItem) => feedItem.unread===true)
      if(findFirstUnreadIndex > 0){
        vListRef.current?.scrollToIndex(findFirstUnreadIndex, { align: "start" });
        setActiveIndex(findFirstUnreadIndex)
        setInitialScroll(()=>true)
      } else {
        vListRef.current?.scrollToIndex(feedMap?.length - 1, { align: "start" });
        setActiveIndex(feedMap?.length - 1)
        setInitialScroll(()=>true)
      }
    }
  }, [workspaceId, initialScroll, feedId, feedMapIds]);

    return (
      <VList
        aria-label={Locator.feed.items.list}
        data-testid={Locator.feed.testId(feedId)}
        style={{
          height: "calc(100svh - 92px)",
          paddingBottom:'200px',
          boxShadow: scrolling ? 'inset 0 0 0 4px rgba(181, 0, 255, 0.75)' : '',
          position:'relative'
        }}
        reverse
        shift={false}
        overscan={0}
        ref={vListRef}
        id="vListRef"
        itemSize={150}
        onRangeChange={(startIndex, endIndex)=>{
          const range = [startIndex, endIndex + 5]
          setRange(()=> range)
        }}
        onScroll={()=>{
          setScrolling(()=>true)
        }}
        onScrollEnd={async()=>{
          setScrolling(()=>false)
          await downloadFeedItems()
        }}
      >
        {feedMap?.map((feedItem, index) => (
          <Box
            key={`feed-item-${feedItem?.id}`}
            sx={{position:'relative', background:activeIndex===index && index !== feedMap?.length - 1 ? 'rgba(181, 0, 255, 0.3)' : ''}}
          >
            <FeedItem
              feed={currentFeed}
              item={feedItem}
              vListIndex={index}
              active={
                feedItem.id === feedItemId ||
                feedItem.id === activeFeedItemId
              }
              highlightPodcast={feedItem.id === activeFeedItemId}
            />
          </Box>
        ))}
      </VList>
    )
  }
