import DatePicker, { DatePickerVariant } from "@/components/DatePicker";
import TimePicker from "@/components/TimePicker";
import {
  addDays,
  getDay,
  getHours,
  getMinutes,
  isFuture,
  isValid,
  setHours,
  setMinutes,
  setSeconds,
  startOfDay,
} from "date-fns";
import ScheduleMessageRecurringOptions from "@/components/Scheduling/ScheduleMessageRecurringOptions";

type Props = {
  type: "date" | "time" | "daysOfWeek";
  dateTime: Date;
  label?: string;
  doesRepeat: boolean;
  saving: boolean;
  setDateTime: (date: Date, dayOfWeek: number) => void;
  setMutableDoesRepeat?: (value: boolean) => void;
  daysOfWeek?: number;
  setInvalidTime?: (value: boolean) => void;
};
export default function ScheduleDateTimePicker({
  daysOfWeek,
  saving,
  doesRepeat,
  type,
  label,
  dateTime,
  setDateTime,
  setMutableDoesRepeat,
  setInvalidTime,
}: Props) {
  let minDatetime = null;
  const isTimeInFuture = isFuture(dateTime);
  if (isTimeInFuture) {
    minDatetime = startOfDay(dateTime);
  } else if (!doesRepeat) {
    minDatetime = dateTime;
  }

  const handleDateSelection = (newDateTime: Date) => {
    const pastTime = newDateTime.getTime() - new Date().getTime() < 0;
    if (pastTime) {
      setInvalidTime(true);
    } else if (isValid(newDateTime)) {
      const dayOfWeek = getDay(newDateTime) + 1;
      setDateTime(newDateTime, dayOfWeek);
      setInvalidTime(false);
    } else {
      setInvalidTime(true);
    }
  };

  const handleSelectedDaysOfWeek = (days: number[]) => {
    const targetDay = days?.[0] - 1;
    const todaysDate = new Date();
    const todaysDay = getDay(todaysDate);
    // Calculate the number of days to add to reach the target day
    const daysToAdd = (targetDay - todaysDay + 7) % 7;
    const currentHrs = getHours(dateTime);
    const currentMins = getMinutes(dateTime);
    // Create a new date object for the next target day
    let nextTargetDate = addDays(todaysDate, daysToAdd);
    nextTargetDate = setHours(nextTargetDate, currentHrs);
    nextTargetDate = setMinutes(nextTargetDate, currentMins);
    nextTargetDate = setSeconds(nextTargetDate, 0);
    setDateTime(nextTargetDate, days[0]);
  };

  if (type === "date") {
    return (
      <DatePicker
        label={label}
        variant={DatePickerVariant.Dropdown}
        selectedDate={dateTime}
        dateSelectionHandler={(date: Date) => handleDateSelection(date)}
        disabled={saving || doesRepeat}
      />
    );
  }

  if (type === "time") {
    return (
      <TimePicker
        label={label}
        includeTimeZone={false}
        selectedTime={dateTime}
        minDate={minDatetime}
        timeSelectionHandler={(date: Date) => handleDateSelection(date)}
        disabled={saving}
      />
    );
  }

  if (type === "daysOfWeek") {
    return (
      <ScheduleMessageRecurringOptions
        doesRepeat={doesRepeat}
        doesRepeatHandler={(value: boolean) => {
          setMutableDoesRepeat(value);
        }}
        disabled={saving}
        selectedDate={dateTime}
        selectedDaysOfWeek={[daysOfWeek]}
        selectedDaysOfWeekHandler={(days) => handleSelectedDaysOfWeek(days)}
      />
    );
  }
}
