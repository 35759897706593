import { useContext } from "react";
import { AppContext } from "@/models/AppStateProvider";
import { Divider, Stack } from "@mui/material";

export default function TranscriptDetails({
  transcriptionContent,
  files,
  links,
}) {
  const { flags } = useContext(AppContext);
  return (
    <div>
      {flags.debugMode && (
        <>
          <Divider sx={{ pt: 2, pb: 2 }} />
          <Stack sx={{ wordBreak: "break-word" }}>
            <div>
              <strong>Language: </strong>
              {transcriptionContent?.language}
            </div>
            <div>
              <strong>Translated From: </strong>
              {transcriptionContent?.translatedFrom}
            </div>
            <div>
              <strong>Priority: </strong>
              {transcriptionContent?.priority}
            </div>
            <div>
              <strong>model: </strong>
              {transcriptionContent?.backendModel}
            </div>
            <div>
              <strong>Type: </strong>
              {transcriptionContent?.transcriptionType}
            </div>
            <div>
              <strong>Format: </strong>
              {transcriptionContent?.format}
            </div>
            {files?.map((file) => (
              <div key={file.url}>
                <strong>File: </strong>
                {file.url}
              </div>
            ))}
            {links?.map((link) => (
              <div key={link?.url}>
                <strong>Link: </strong>
                {link?.url}
              </div>
            ))}
          </Stack>
        </>
      )}
    </div>
  );
}
