import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemText,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { MODAL_DEFAULT_Z_INDEX } from "@/elements/ModalForm";
import { FullInput } from "@/components/Utils";
import { useMemo, useState } from "react";
import Fuse from "fuse.js";

type Props = {
  channels: { id: string; title: string }[];
  selectedChannels: { id: string; title: string }[];
  updateSelectedChannels: (value: { id: string; title: string }[]) => void;
};
export default function ScheduleChannels({
  channels,
  selectedChannels,
  updateSelectedChannels,
}: Props) {
  const theme = useTheme();
  const [saving, setSaving] = useState<boolean>(false);
  const [searchField, setSearchField] = useState<string>("");
  const fuse = new Fuse(channels, {
    useExtendedSearch: true,
    keys: ["title", "id"],
  });

  const searchedChannels = useMemo(() => {
    if (searchField) {
      return fuse
        .search(`'${searchField}`)
        ?.map((result) => result.item)
        ?.sort((a, b) => a?.title?.localeCompare(b?.title));
    }
    return channels;
  }, [searchField, fuse, channels]);

  const handleToggle = (id: string, title: string) => () => {
    const currentIndex = selectedChannels?.findIndex(
      (channel) => channel?.id === id,
    );
    const newChecked = [...selectedChannels];
    if (currentIndex === -1) {
      newChecked.push({ id, title });
    } else {
      newChecked.splice(currentIndex, 1);
    }
    updateSelectedChannels(newChecked);
  };

  const secondaryAction = (channel: { id: string; title: string }) => {
    return (
      <Checkbox
        edge="end"
        onChange={handleToggle(channel?.id, channel?.title)}
        checked={
          selectedChannels?.findIndex(
            (newChannel) => newChannel?.id === channel?.id,
          ) !== -1
        }
        inputProps={{ "aria-labelledby": channel?.id }}
        color="primary"
      />
    );
  };

  return (
    <FormControl>
      <InputLabel id="channels-label">Channels</InputLabel>
      <Select
        labelId="channels-label"
        sx={{ width: "100%" }}
        value={selectedChannels}
        MenuProps={{
          slotProps: {
            paper: {
              className: "scrollable-content",
              sx: {
                width: "100%",
                overflowY: "hidden",
                maxWidth: { xs: "calc(100% - 32px)", sm: "520px" },
              },
            },
          },
          sx: {
            zIndex: MODAL_DEFAULT_Z_INDEX,
            mt: 1,
            maxHeight: 350,
          },
          MenuListProps: {
            sx: {
              pt: 0,
            },
          },
        }}
        multiple
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
            }}
          >
            {selected.map((s) => (
              <Chip
                sx={{
                  background: theme.palette.secondary.main,
                  color: theme.palette.primary.main,
                  borderRadius: "4px",
                }}
                key={s?.id}
                label={s?.title}
              />
            ))}
          </Box>
        )}
        disabled={saving}
      >
        <Box
          sx={{
            px: 1,
            py: 1,
            position: "sticky",
            top: 0,
            background: theme.palette.secondary.dark,
            zIndex: MODAL_DEFAULT_Z_INDEX,
          }}
        >
          <FullInput
            fullWidth
            callback={(e) => setSearchField(e.target.value)}
            value={searchField}
          />
        </Box>
        {searchedChannels?.length > 0 ? (
          searchedChannels?.map((channel, index) => (
            <ListItem
              key={`${channel?.id}-${index}`}
              secondaryAction={secondaryAction(channel)}
              disablePadding
            >
              <ListItemButton
                onClick={handleToggle(channel?.id, channel?.title)}
                disableRipple
              >
                <ListItemText id={channel?.id} primary={channel?.title} />
              </ListItemButton>
            </ListItem>
          ))
        ) : (
          <ListItem
            key={"no-results"}
            disablePadding
            sx={{ textAlign: "center" }}
          >
            <ListItemText
              primary={
                <Typography sx={{ fontWeight: 700 }}>
                  No channels found
                </Typography>
              }
            />
          </ListItem>
        )}
      </Select>
    </FormControl>
  );
}
