import ModalForm from "@/elements/ModalForm";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import ScheduleChannels from "@/components/Scheduling/ScheduleChannels";
import { parseCronScheduledDate } from "@/utils";
import ScheduleDateTimePicker from "@/components/Scheduling/ScheduleDateTimePicker";
import { getDay, getHours, getMinutes } from "date-fns";
import LoadingButton from "@mui/lab/LoadingButton";
import ConfirmDiscardChanges from "@/components/ModalForms/ConfirmDiscardChanges";
import { SchedulingContext } from "@/models/SchedulingContextProvider";
import { ActionContext } from "@/models/ActionsProvider";
import { CheckCircleOutlined } from "@mui/icons-material";
import { UxContext } from "@/models/UxStateProvider";
import { BroadcastAction, ScheduleTrigger, WorkflowItem } from "@/db/types";

type channelType = { id: string; title: string };
type Props = {
  broadcastAction: BroadcastAction;
  channels: channelType[];
  editMessageModalOpen: boolean;
  openEditMessageModalHandler: (value: boolean) => void;
  scheduledTrigger: ScheduleTrigger;
  selectedChannels: channelType[];
  workflowItem: WorkflowItem;
};
export default function EditScheduledMessage({
  broadcastAction,
  channels,
  editMessageModalOpen,
  openEditMessageModalHandler,
  scheduledTrigger,
  selectedChannels,
  workflowItem,
}: Props) {
  const { updateScheduledWorkflowItem } = useContext(ActionContext);
  const { isSmUp } = useContext(UxContext);
  const { timezone } = useContext(SchedulingContext);
  const [error, setError] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [mutableChannels, setMutableChannels] =
    useState<channelType[]>(selectedChannels);

  const scheduledDate =
    scheduledTrigger?.cronSchedule && scheduledTrigger?.timezone
      ? parseCronScheduledDate(
          scheduledTrigger?.cronSchedule,
          scheduledTrigger?.timezone,
        )
      : new Date(scheduledTrigger?.oneTimeSchedule);

  const dayOfWeek = getDay(scheduledDate) + 1;
  const [mutableDayOfWeek, setMutableDayOfWeek] = useState<number>(dayOfWeek);

  const [mutableDate, setMutableDate] = useState<Date>(scheduledDate);
  const doesRepeat = !!scheduledTrigger?.cronSchedule;
  const [mutableDoesRepeat, setMutableDoesRepeat] =
    useState<boolean>(doesRepeat);

  const selectedChannelsString = selectedChannels
    ?.map((channel) => channel.id)
    .sort()
    .join();

  const mutableChannelsString = mutableChannels
    ?.map((channel) => channel.id)
    .sort()
    .join();

  /**
   * Check to see if the current channels match the mutated channels
   */
  const channelChange = selectedChannelsString !== mutableChannelsString;

  /**
   * Has changes dictates whether the user has made any changes to the scheduled message
   * If they have, you need to confirm if they want to discard the changes
   */
  const hasChanges =
    channelChange ||
    dayOfWeek !== mutableDayOfWeek ||
    doesRepeat !== mutableDoesRepeat ||
    mutableDate !== scheduledDate;

  const [confirmChanges, setConfirmChanges] = useState<boolean>(false);

  const handleClose = () => {
    if (hasChanges) {
      setConfirmChanges(() => true);
    } else {
      openEditMessageModalHandler(false);
    }
  };

  const calculateCron = () => {
    const mins = getMinutes(mutableDate);
    const hours = getHours(mutableDate);
    const month = "*";
    let dayOfMonth = "?";
    const daysOfWeek = mutableDayOfWeek.toString();
    const scheduledCron = `${mins} ${hours} ${dayOfMonth} ${month} ${daysOfWeek} *`;
    return {
      scheduledDate: "",
      scheduledCron,
      timezone,
    };
  };

  const saveWorkflowItem = async () => {
    const cronTime = mutableDoesRepeat
      ? calculateCron()
      : { scheduledDate: mutableDate.toISOString() };

    const payload = {
      workspaceId: workflowItem.workspaceId,
      scheduleId: scheduledTrigger.id,
      feedIds: mutableChannels.map((channel) => channel.id),
      ...cronTime,
    };

    setSaving(() => true);

    await updateScheduledWorkflowItem(payload);

    setSaved(() => true);

    setTimeout(() => {
      setSaving(() => false);
      setConfirmChanges(() => false);
      openEditMessageModalHandler(false);
    }, 2000);
  };

  return (
    <ModalForm
      id={`edit-${broadcastAction.id}`}
      open={editMessageModalOpen}
      onClose={() => openEditMessageModalHandler(false)}
      disableClose={hasChanges}
      sx={{ flexGrow: 1, padding: { xs: 2, sm: "64px 40px" } }}
    >
      <Stack sx={{ width: "100%", gap: 3.5 }}>
        <Typography variant="h6" component="h3" sx={{ alignSelf: "center" }}>
          {workflowItem?.displayName}
        </Typography>

        <Stack sx={{ gap: "5px" }}>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Box sx={{ width: "100%", maxWidth: "50%" }}>
              <ScheduleDateTimePicker
                label="Date and Time"
                type="date"
                dateTime={mutableDate}
                saving={saving}
                doesRepeat={mutableDoesRepeat}
                setInvalidTime={(value) => setError(() => value)}
                setDateTime={(date, dayOfWeek) => {
                  setMutableDayOfWeek(() => dayOfWeek);
                  setMutableDate(() => date);
                }}
              />
            </Box>
            <Box sx={{ width: "100%", maxWidth: "50%" }}>
              <ScheduleDateTimePicker
                type="time"
                label=""
                dateTime={mutableDate}
                saving={saving}
                setInvalidTime={(value) => setError(() => value)}
                doesRepeat={mutableDoesRepeat}
                setDateTime={(date, dayOfWeek) => {
                  setMutableDayOfWeek(() => dayOfWeek);
                  setMutableDate(() => date);
                }}
              />
            </Box>
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: 0,
            }}
          >
            <Box sx={{ width: "100%", maxWidth: "50%", pr: 1 }}>
              <ScheduleDateTimePicker
                type="daysOfWeek"
                label=""
                dateTime={mutableDate}
                saving={saving}
                doesRepeat={mutableDoesRepeat}
                daysOfWeek={mutableDayOfWeek}
                setInvalidTime={(value) => setError(() => value)}
                setDateTime={(date, dayOfWeek) => {
                  setMutableDayOfWeek(() => dayOfWeek);
                  setMutableDate(() => date);
                }}
                setMutableDoesRepeat={(value: boolean) =>
                  setMutableDoesRepeat(() => value)
                }
              />
            </Box>
            {error && <Box sx={{ pl: 2, color: "red" }}>Invalid Date/Time</Box>}
          </Stack>
        </Stack>

        <ScheduleChannels
          channels={channels}
          selectedChannels={mutableChannels}
          updateSelectedChannels={(value) => {
            setMutableChannels(value);
          }}
        />

        <Stack
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            width: "100%",
            gap: 2,
          }}
        >
          <Button
            variant="outlined"
            sx={{ order: { xs: 1, sm: 0 } }}
            onClick={handleClose}
            disabled={saving}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={saving}
            disabled={error || !hasChanges}
            variant="contained"
            color="primary"
            sx={{ order: { xs: 0, sm: 1 } }}
            onClick={saveWorkflowItem}
          >
            Save
          </LoadingButton>
        </Stack>

        {saved && (
          <Alert
            variant="filled"
            severity="success"
            sx={{
              position: "fixed",
              width: "100%",
              left: 0,
              top: isSmUp ? `calc(100% + 24px)` : 0,
              borderRadius: isSmUp ? "12px" : 0,
              "& .MuiAlert-icon": {
                padding: 0,
              },
            }}
            iconMapping={{
              success: <CheckCircleOutlined fontSize="inherit" sx={{ p: 0 }} />,
            }}
          >
            Scheduled message updated
          </Alert>
        )}

        <ConfirmDiscardChanges
          id="discard-scheduled-message-edits"
          visible={confirmChanges}
          text="Are you sure you want to discard these changes?"
          denyCloseHandler={() => {
            setConfirmChanges(() => false);
          }}
          confirmCloseHandler={() => {
            openEditMessageModalHandler(false);
            setConfirmChanges(() => false);
          }}
        />
      </Stack>
    </ModalForm>
  );
}
