import ModalForm from "@/elements/ModalForm";
import Locator from "@/locator";
import { SchedulingContext } from "@/models/SchedulingContextProvider";
import { UxContext } from "@/models/UxStateProvider";
import { Stack, Typography } from "@mui/material";
import { useCallback, useContext, useState } from "react";
import ConfirmDiscardChanges from "../ModalForms/ConfirmDiscardChanges";
import FleetMessageChannelSelect from "./FleetMessageChannelSelect";
import FleetMessageContentSelect from "./FleetMessageContentSelect";

export default function FleetMessageModal() {
  const { fleetMessageModalOpen, setFleetMessageModalOpen } =
    useContext(UxContext);
  const { currentStep, resetAll, unsavedChanges } =
    useContext(SchedulingContext);
  const [confirmUnsavedChanges, setConfirmUnsavedChanges] =
    useState<boolean>(false);

  const title = () => {
    let stepTitle: string;
    switch (currentStep) {
      case 2:
        stepTitle = "Select recipients";
        break;
      case 1:
      default:
        stepTitle = "New Fleet Message";
        break;
    }
    return stepTitle;
  };

  const confirmClose = () => {
    resetAll();
    setConfirmUnsavedChanges(false);
    setFleetMessageModalOpen(false);
  };

  const denyClose = () => {
    setConfirmUnsavedChanges(false);
  };

  const handleClose = useCallback(() => {
    if (unsavedChanges) {
      setConfirmUnsavedChanges(true);
    } else {
      confirmClose();
    }
  }, [unsavedChanges, confirmClose]);

  return (
    <>
      <ModalForm
        id="fleet-message"
        maxHeight={620}
        open={fleetMessageModalOpen}
        onClose={handleClose}
        sx={{
          justifyContent: "center",
          flexGrow: 1,
          padding: { xs: 2, sm: "78px 64px 64px 64px" },
        }}
      >
        <>
          <Stack
            sx={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              gap: 2.5,
            }}
            aria-label={Locator.feed.input.fleetMessage.modal.name}
          >
            <Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
              {title()}
            </Typography>
            {currentStep === 1 ? (
              <FleetMessageContentSelect cancelHandler={handleClose} />
            ) : null}
            {currentStep === 2 ? <FleetMessageChannelSelect /> : null}
          </Stack>
        </>
      </ModalForm>
      <ConfirmDiscardChanges
        id="discard-fleet-message"
        visible={confirmUnsavedChanges}
        denyCloseHandler={denyClose}
        confirmCloseHandler={confirmClose}
      />
    </>
  );
}
