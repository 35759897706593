import { Box, Button, useTheme } from "@mui/material";
import * as Icons from "@mui/icons-material";
import type React from "react";
import BasicButtonStyles from "@/theme/BasicButtonStyles";

export default function HideShowTranscriptButton({
  showTranscription,
  onClick,
  mine,
  displayArtifact,
}: {
  showTranscription: boolean;
  mine: boolean;
  displayArtifact: boolean;
  onClick: () => void;
}) {
  const { normalButtonStyles, normalIconStyles } = BasicButtonStyles();
  const theme = useTheme();
  const background = displayArtifact
    ? theme.palette.brand.other.teal.light
    : mine
      ? theme.palette.brand.primary.main
      : theme.palette.secondary.main;

  return (
    <Button
      sx={{
        ...normalButtonStyles,
        mt: 1,
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        display: "flex",
        width: "100%",
        padding: 0,
        height: "auto",
        background,
      }}
      aria-label={showTranscription ? "Show less" : "Show more"}
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <Box sx={{ mr: 1, display: "flex" }}>
        {showTranscription ? (
          <Icons.KeyboardArrowUp role="img" sx={{ fontSize: "20px" }} />
        ) : (
          <Icons.KeyboardArrowDown role="img" sx={{ fontSize: "20px" }} />
        )}
      </Box>

      <Box>{showTranscription ? "Hide" : "Show"} full transcription</Box>
    </Button>
  );
}
