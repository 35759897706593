import { db } from "@/db/db";
import { useDrizzleQuery } from "@/db/drizzleUtils";
import { audioEncoding } from "@/db/schema";
import { Item } from "@/db/types";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { asc, eq } from "drizzle-orm";
import "regenerator-runtime/runtime";

export default function AudioEncodingsTable({ item }: { item: Item }) {
  const { rows: encodings } = useDrizzleQuery(
    db.query.audioEncoding.findMany({
      where: eq(audioEncoding.contentId, item.contentId),
      orderBy: asc(audioEncoding.language),
    }),
  );

  const encodingsToCSV = () => {
    if (!encodings || !encodings.length) return "";

    const header = Object.keys(encodings[0]);

    const rows = encodings.map((obj) => {
      return header
        .map((fieldName) =>
          typeof obj[fieldName] === "string" && obj[fieldName].includes(",")
            ? `"${obj[fieldName]}"`
            : obj[fieldName],
        )
        .join(",");
    });

    const data = [header.join(","), ...rows].join("\n");
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `audioEncodings_${encodings[0].contentId}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Button onClick={encodingsToCSV} variant="contained" color="primary">
        Download CSV
      </Button>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Codec</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>MimeType</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Language</TableCell>
              <TableCell>Translated From</TableCell>
              <TableCell>Transcription Type</TableCell>
              <TableCell>Generated Voice</TableCell>
              <TableCell>Generated Service</TableCell>
              <TableCell>Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {encodings?.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell>{row.codec}</TableCell>
                <TableCell>{row.priority}</TableCell>
                <TableCell>{row.mimeType}</TableCell>
                <TableCell>{row.duration}</TableCell>
                <TableCell>{row.language}</TableCell>
                <TableCell>{row.translatedFrom}</TableCell>
                <TableCell>{row.transcriptionType}</TableCell>
                <TableCell>{row.generatedVoice}</TableCell>
                <TableCell>{row.generatedService}</TableCell>
                <TableCell>
                  <a target="_blank" href={row.url} rel="noreferrer">
                    File
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
