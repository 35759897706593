import { useDrizzleSelect } from "@/db/drizzleUtils";
import { Item } from "@/db/types";
import { createContext, useContext, useEffect } from "react";
import type { AccountEventType } from "web-client/api/data-contracts";
import { WorkspaceContext } from "./StateProviders/workspaceProvider";
import { unreadFeedIdsQuery } from "./commonQueries";

type UnreadFeed = {
  feedId: string;
  unreadItems: Array<Item>;
};

export const unreadClearEvents: AccountEventType[] = [
  "Saw Feed Item",
  "Marked Feed as Read",
  "Opened Feed",
  "Started Listening to Feed Item", // used for in-line feed views
];

type UnreadsState = {
  allUnreadFeeds?: Array<UnreadFeed>;
  unreadFeedIds?: Array<string>;
};

export const UnreadsContext = createContext<UnreadsState>({
  allUnreadFeeds: [],
});

const UnreadsContextProvider = ({ children }) => {
  const { myCurrentWorkspaceMembership, currentWorkspaceId } =
    useContext(WorkspaceContext);

  const { rows: unreadFeedIds } = useDrizzleSelect(
    unreadFeedIdsQuery({
      myCurrentWorkspaceMembershipId: myCurrentWorkspaceMembership?.id,
    }),
  );

  const addBadge = (favicon) => {
    favicon.href = "/favicon-unread.png";
  };

  const removeBadge = (favicon) => {
    // reset the back to the default favicon
    favicon.href = "/favicon.png";
  };

  const updateDocumentTitle = (unreadFeedCount = 0) => {
    const baseTitle = document.title.replace(/\(\d+\)\s*/, ""); // Remove any existing (unreadCount)
    if (unreadFeedCount > 0) {
      document.title = `(${unreadFeedCount}) ${baseTitle}`;
    } else {
      document.title = baseTitle;
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <too many dependencies cause troubles>
  useEffect(() => {
    if (!unreadFeedIds) return;
    if (unreadFeedIds?.length > 0) {
      document
        .querySelectorAll(
          'link[rel="mask-icon"], link[rel="icon"], link[rel="apple-touch-icon"]',
        )
        .forEach(addBadge);
      updateDocumentTitle(unreadFeedIds?.length);
    } else {
      document
        .querySelectorAll(
          'link[rel="mask-icon"], link[rel="icon"], link[rel="apple-touch-icon"]',
        )
        .forEach(removeBadge);
      updateDocumentTitle();
    }
  }, [unreadFeedIds?.length]);

  const unreadsState: UnreadsState = {
    unreadFeedIds: unreadFeedIds?.map((ufi) => ufi.feedId),
  };

  return (
    <UnreadsContext.Provider value={unreadsState}>
      {children}
    </UnreadsContext.Provider>
  );
};

export default UnreadsContextProvider;
