import { DataContext } from "@/models/DataProvider";
import { Stack, Typography, useTheme } from "@mui/material";
import {
  useEffect,
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
} from "react";
import { FullTooltip } from "./Utils";
import { Lock as LockIcon, VolumeOffOutlined } from "@mui/icons-material";
import { UxContext } from "@/models/UxStateProvider";
import Locator from "@/locator";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";

export default function FeedHeader() {
  const [isOverflowTitle, setIsOverFlowTitle] = useState(undefined);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const resizingDelayTimer = useRef(null);
  const theme = useTheme();

  const { navTitle } = useContext(UxContext);

  const { currentFeed, currentFeedAccounts } = useContext(CurrentFeedContext);

  const accounts = Array.from(currentFeedAccounts?.values());

  const resizeTrigger = () => {
    if (titleRef.current === undefined) {
      return;
    }
    const hasOverflow =
      titleRef.current.offsetWidth < titleRef.current.scrollWidth ||
      titleRef.current.offsetHeight < titleRef.current.scrollHeight;
    if (hasOverflow === isOverflowTitle) {
      return;
    }
    setIsOverFlowTitle(hasOverflow);
  };

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      // Cancel the previous frame
      if (resizingDelayTimer.current) {
        cancelAnimationFrame(resizingDelayTimer.current);
      }
      // Schedule the callback to be run in the next frame
      resizingDelayTimer.current = requestAnimationFrame(() => {
        // check if the observed div is still mounted
        // else this will cause memory leak
        if (titleRef.current) {
          resizeTrigger();
        }
      });
    });
    observer.observe(titleRef.current);
    return () => {
      if (observer && titleRef.current) observer.unobserve(titleRef.current);
    };
  }, []);

  const membersLabel = useMemo(() => {
    if (!accounts || accounts.length === 0) return;
    const label = `member${accounts?.length > 1 ? "s" : ""}`;
    return `${accounts?.length} ${label}`;
  }, [
    accounts
      ?.map((a) => a.id)
      .sort()
      .join(","),
  ]);

  const displayTitle =
    currentFeed?.title || accounts?.map((a) => a.name).join(", ") || navTitle;

  const header = (
    <Typography
      ref={titleRef}
      aria-label={Locator.mainNav.displayTitle}
      variant="h6"
      component="h2"
      sx={{
        fontWeight: 700,
        width: "100%",
      }}
      noWrap
    >
      {displayTitle}
    </Typography>
  );

  return (
    <Stack
      aria-label={Locator.mainNav.navBar}
      sx={{
        width: "100%",
        py: 2,
        minWidth: 0,
        alignItems: {
          xs: currentFeed?.id ? "flex-start" : "center",
          sm: "center",
        },
        justifyContent: "center",
        textAlign: {
          xs: currentFeed?.id ? "left" : "center",
          sm: "center",
        },
      }}
    >
      {!isOverflowTitle ? (
        header
      ) : (
        <FullTooltip
          disableFocusListener={!isOverflowTitle}
          disableHoverListener={!isOverflowTitle}
          disableTouchListener={!isOverflowTitle}
          title={displayTitle}
          placement="bottom-start"
        >
          {header}
        </FullTooltip>
      )}
      <Typography
        variant="subtitle2"
        component="div"
        sx={{
          fontWeight: 400,
          color: theme.palette.neutral.main,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {" "}
        {currentFeed?.isPrivate && (
          <LockIcon
            sx={{ fontSize: 12, mr: 0.5 }}
            aria-label="Private Channel"
          />
        )}
        {membersLabel}
        {currentFeed?.isSilent && (
          <Typography
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 12,
            }}
          >
            &nbsp;•&nbsp;
            <VolumeOffOutlined sx={{ fontSize: 16, mr: 0.25 }} />
            <i>Hands-free is off</i>
          </Typography>
        )}
        {currentFeed?.readOnly && <span>&nbsp;•&nbsp;Listen-only mode</span>}
      </Typography>
    </Stack>
  );
}
