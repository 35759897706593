import {
  Box,
  Checkbox,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

export function MapDaysOfWeek(index) {
  return dayOptions.find((d) => d.value === index)?.day;
}

// aws cron day of week format
// https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-cron-expressions.html
const dayOptions = [
  { value: 1, label: "S", day: "Sunday" },
  { value: 2, label: "M", day: "Monday" },
  { value: 3, label: "T", day: "Tuesday" },
  { value: 4, label: "W", day: "Wednesday" },
  { value: 5, label: "T", day: "Thursday" },
  { value: 6, label: "F", day: "Friday" },
  { value: 7, label: "S", day: "Saturday" },
];

export default function DaysOfWeekPicker({
  label = "",
  selectedDays,
  daySelectionHandler,
  multiDay = true,
}) {
  const theme = useTheme();

  const handleToggle = (value) => () => {
    const currentIndex = selectedDays?.indexOf(value);
    const newChecked = multiDay ? [...selectedDays] : [];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    daySelectionHandler(newChecked);
  };

  return (
    <Stack sx={{ width: "100%", gap: 1 }}>
      <Typography>{label}</Typography>
      <Stack sx={{ width: "100%", gap: 1, flexDirection: "row" }}>
        {multiDay ? (
          dayOptions.map((day) => (
            <Checkbox
              key={`day-of-week-${day.value}`}
              onChange={handleToggle(day.value)}
              checked={selectedDays?.indexOf(day?.value) !== -1}
              inputProps={{ "aria-labelledby": day?.label }}
              color="primary"
              sx={{
                borderRadius: "100px",
                background:
                  selectedDays?.indexOf(day?.value) !== -1
                    ? theme.palette.brand.primary.main
                    : "rgba(255, 255, 255, 0.10)",
                boxShadow: "0px 24px 40px 0px rgba(26, 26, 26, 0.16)",
                color: theme.palette.primary.main,
                width: 40,
                height: 40,
                p: 1.5,
                minWidth: 0,
                fontWeight: 500,
              }}
              icon={<Box>{day.label}</Box>}
              checkedIcon={
                <Box sx={{ color: theme.palette.primary.main }}>
                  {day.label}
                </Box>
              }
            />
          ))
        ) : (
          <RadioGroup row sx={{ gap: 1 }}>
            {dayOptions.map((day) => (
              <Tooltip title={day.day} key={`day-of-week-${day.value}`}>
                <Radio
                  onChange={handleToggle(day.value)}
                  checked={selectedDays?.indexOf(day?.value) !== -1}
                  inputProps={{ "aria-labelledby": day?.label }}
                  color="primary"
                  sx={{
                    borderRadius: "100px",
                    background:
                      selectedDays?.indexOf(day?.value) !== -1
                        ? theme.palette.brand.primary.main
                        : "rgba(255, 255, 255, 0.10)",
                    boxShadow: "0px 24px 40px 0px rgba(26, 26, 26, 0.16)",
                    color: theme.palette.primary.main,
                    width: 40,
                    height: 40,
                    p: 1.5,
                    minWidth: 0,
                    fontWeight: 500,
                  }}
                  icon={<Box>{day.label}</Box>}
                  checkedIcon={
                    <Box sx={{ color: theme.palette.primary.main }}>
                      {day.label}
                    </Box>
                  }
                  disableRipple
                />
              </Tooltip>
            ))}
          </RadioGroup>
        )}
      </Stack>
    </Stack>
  );
}
