import { db } from "@/db/db";
import { useDrizzleQuery } from "@/db/drizzleUtils";
import { link } from "@/db/schema";
import { Item } from "@/db/types";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { eq } from "drizzle-orm";
import "regenerator-runtime/runtime";

export default function ItemFilesTable({ item }: { item: Item }) {
  const { rows: links } = useDrizzleQuery(
    db.query.link.findMany({
      where: eq(link.contentId, item.contentId),
    }),
  );
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Url</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Image</TableCell>
            <TableCell>Id</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {links?.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <a target="_blank" href={row.url} rel="noreferrer">
                  {row.url}
                </a>
              </TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>
                <a target="_blank" href={row.image} rel="noreferrer">
                  {row.image}
                </a>
              </TableCell>
              <TableCell>{row.id}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
