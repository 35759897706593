import { db } from "@/db/db";
import { useDrizzleQuery } from "@/db/drizzleUtils";
import { file } from "@/db/schema";
import { Item } from "@/db/types";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { eq } from "drizzle-orm";
import "regenerator-runtime/runtime";

export default function ItemFilesTable({ item }: { item: Item }) {
  const { rows: files } = useDrizzleQuery(
    db.query.file.findMany({
      where: eq(file.contentId, item.contentId),
    }),
  );

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>File</TableCell>
            <TableCell>Id</TableCell>
            <TableCell>MimeType</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files?.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <a target="_blank" href={row.url} rel="noreferrer">
                  {row.name}
                </a>
              </TableCell>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.mimeType}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
