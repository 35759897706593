import Loading from "@/components/Loading/Index";
import { db } from "@/db/db";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import { feed } from "@/db/schema";
import { AppContext } from "@/models/AppStateProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { initialFeedLoad } from "@/models/actions/initialFeedLoad";
import { myActiveFeedsQuery } from "@/models/commonQueries";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { count } from "drizzle-orm";
import { useContext, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function WorkspaceId() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { client } = useContext(AppContext);
  const { currentWorkspaceId, myCurrentWorkspaceMembership } =
    useContext(WorkspaceContext);
  const workspaceId = currentWorkspaceId;

  const { rows: myActiveFeeds } = useDrizzleSelect(
    myActiveFeedsQuery({
      workspaceId,
      myCurrentWorkspaceMembershipId: myCurrentWorkspaceMembership?.id,
    }),
  );

  const activeFeedMap = useMemo(
    () =>
      myActiveFeeds
        ?.filter((feed) => feed.workspaceId === workspaceId)
        ?.map((item) => item.id)
        .join("-"),
    [myActiveFeeds, workspaceId],
  );

  const wrapper = (children) => (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          padding: "40px 60px",
          borderRadius: "8px",
          textAlign: "center",
          background: theme.palette.secondary.dark,
        }}
      >
        {children}
      </Box>
    </Box>
  );

  const hasLoadedFeedItems = useRef<boolean>(false);

  const { rows: feedCountResults } = useDrizzleSelect(
    db.select({ count: count() }).from(feed).orderBy(),
  );
  const feedCount = feedCountResults?.[0]?.count || 0;

  useEffect(() => {
    const feedId = myActiveFeeds[0]?.id;

    console.log("FeedId", feedId, myActiveFeeds);

    if (!hasLoadedFeedItems.current && feedId) {
      initialFeedLoad(client, feedId).then((data) => {
        hasLoadedFeedItems.current = true;
        console.log("Feed Loaded", feedId);
        const redirectUrl = `/workspaces/${workspaceId}/feeds/${feedId}`;
        navigate(redirectUrl);
      });
    }
  }, [myActiveFeeds]);

  if (
    (feedCount === 0 && !activeFeedMap) ||
    (feedCount > 0 && !activeFeedMap)
  ) {
    return wrapper(
      <Box sx={{ display: "flex" }}>
        <Box sx={{ mr: 2 }}>Loading</Box>{" "}
        <Loading variant="white" size="large" baseSize={6} />
      </Box>,
    );
  }

  if (feedCount === 0) {
    return wrapper(
      <Box sx={{ display: "flex" }}>
        <Box sx={{ mr: 2 }}>Loading Workspace</Box>{" "}
        <Loading variant="white" size="large" baseSize={6} />
      </Box>,
    );
  }

  if (feedCount > 0 && activeFeedMap) {
    return wrapper(
      <Box sx={{ display: "flex" }}>
        <Box sx={{ mr: 2 }}>Loading Channel</Box>{" "}
        <Loading variant="white" size="large" baseSize={6} />
      </Box>,
    );
  }

  return wrapper("Error Loading Workspace Channel");
}
